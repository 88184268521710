import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import { Container, Wrapper } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AgreementContent = styled.div`
  ol {
    list-style-type: upper-alpha;
  }
`;

export const query = graphql`
  query {
    contentfulUserAgreement(type: { eq: "Info" }) {
      lastModified(formatString: "MMMM D, YYYY")
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
      version
    }
  }
`;

const UserAgreementChanges = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, [props.location.origin, props.location.pathname, props.location.search]);

  const website_url = process.env.GATSBY_SITE_URL;

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <div
            className="-addShadow -center"
            css={{ maxWidth: "500px", marginTop: "60px", marginBottom: "60px" }}
          >
            <GatsbyImage
              image={node.data.target.gatsbyImageData}
              alt={node.data.target.title}
              title={node.data.target.title}
            />
          </div>
        );
      },
      [INLINES.HYPERLINK]: (node) => {
        if (
          node.data.uri.startsWith(website_url) ||
          node.data.uri.startsWith("/") ||
          node.data.uri.startsWith("#")
        ) {
          return (
            <Link to={node.data.uri}>
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </Link>
          );
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </a>
          );
        }
      },
    },
  };

  return (
    <Layout>
      <Seo
        title="User Agreement Changes | FlexiTime"
        description="We've modernised and consolidated all of of our user agreements following FlexiTime's acquisition of Invoxy."
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Wrapper stackGap={80} maxWidth={1000}>
          <h1 className="-textCenter">
            {props.data.contentfulUserAgreement.title}
          </h1>
          <AgreementContent className="font-bump">
            {renderRichText(
              props.data.contentfulUserAgreement.content,
              options
            )}
          </AgreementContent>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default UserAgreementChanges;
